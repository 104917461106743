<template>
  <v-card flat :loader="loader">
    <div class="my-2 pa-2">
      <v-overlay color="white" :value="loader">
        <v-progress-circular
          :size="50"
          :width="7"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <!-- <v-spacer></v-spacer> -->
      <v-row>
        <!-- <v-col cols="12" md="2"> </v-col>
        <v-col cols="12" md="3">
          <v-btn
            class="mr-3"
            rounded
            outlined
            color="green"
            href="http://pampsi.gamifindo.com/template/dataAlumni.xlsx"
            >Template Import List <v-icon>mdi-download</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" md="2" class="d-flex">
          <v-btn rounded outlined color="green">
            <div class="upload-btn-wrapper add-width">
              <div class="pa-1">
                <v-icon>mdi-upload</v-icon> Import
                <input type="file" @change="importList($event)" name="myfile" />
              </div>
            </div>
          </v-btn>
        </v-col> -->
        <v-col cols="12" md="3">
          <v-text-field
            solo
            label="Search"
            dense
            rounded
            append-icon="mdi-magnify"
            hide-details
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            solo
            rounded
            dense
            item-text="state"
            item-value="val"
            :items="filter"
            label="Filter"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <div v-if="registran">
      <div
        v-for="(item, index) in registran"
        :key="`${index}-divider`"
        class="pa-1"
      >
        <v-divider></v-divider>
        <div class="pa-2">
          <v-row>
            <v-col cols="12" md="6">
              <div class="d-flex align-center">
                <v-avatar size="55" class="mr-2">
                  <img
                    class="img-fit"
                    :src="
                      item.photo_profile
                        ? `${env}/upload/photo_profile/${item.id}/${item.photo_profile}`
                        : dummy
                    "
                  />
                </v-avatar>
                <div>
                  <b>{{ item.nama_lengkap }}</b>
                  <p class="small_txt mb-0">
                    {{ item.no_hp }}
                  </p>
                  <p class="small_txt mb-0">
                    {{ item.nama_kota }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  rounded
                  color="green"
                  class="mr-2"
                  @click="confirm(item.id)"
                >
                  <v-icon>mdi-account-check-outline</v-icon> Confirm
                </v-btn>
                <v-btn outlined rounded color="red" @click="rejectPsy(item.id)"
                  ><v-icon>mdi-close</v-icon> Reject</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- <v-list three-line v-if="registran.length">
        <template v-for="(item, index) in registran">
          <v-divider :key="`${index}-divider`"></v-divider>
          <v-list-item :key="index">
            <v-list-item-avatar size="55">
              <img
                class="img-fit"
                :src="
                  item.photo_profile
                    ? `${env}/upload/photo_profile/${item.id}/${item.photo_profile}`
                    : dummy
                "
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <b>{{ item.nama_lengkap }}</b>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon small>mdi-cellphone-iphone</v-icon>
                {{ item.no_hp }}</v-list-item-subtitle
              >
              <v-list-item-subtitle>
                <v-icon small>mdi-map-marker</v-icon>
                {{ item.nama_kota }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <div class="d-flex">
                <v-btn
                  outlined
                  rounded
                  color="green"
                  class="mr-2"
                  @click="confirm(item.id)"
                >
                  <v-icon>mdi-account-check-outline</v-icon> Confirm
                </v-btn>
                <v-btn outlined rounded color="red" @click="rejectPsy(item.id)"
                  ><v-icon>mdi-delete</v-icon> Delete</v-btn
                >
              </div>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list> -->
      <div
        class="d-flex flex-column align-center pa-5"
        v-if="!registran.length"
      >
        <img src="../../assets/img/404.svg" height="100px" alt="" />
        <b>No Data Found!</b>
      </div>
    </div>
    <div class="pa-3">
      <ImportList v-bind:dialogList="dialogList" @false="closeDialog" />
      <Waiting v-if="loading" />
      <notifications
        group="authVerification"
        class="mt-2"
        position="top center"
      />
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Waiting from "../_base/Modal/waiting.vue";
import ImportList from "./Modal/importList.vue";

export default {
  components: { ImportList, Waiting },
  name: "registredList",
  computed: {
    ...mapState({
      registran: state => state.admin.registran,
      loading: state => state.admin.loading,
      env: state => state.API_URL,
      dummy: state => state.dummy
    })
  },
  data() {
    return {
      loader: false,
      file: null,
      dialogList: false,
      filter: [
        { state: "Nama", val: "Nama" },
        { state: "Campus", val: "Campus" }
      ]
    };
  },
  created() {
    this.fetchRegistran();
  },

  methods: {
    fetchRegistran() {
      this.loader = true;
      this.$store.dispatch("admin/listRegistran").then(() => {
        this.loader = false;
      });
    },

    confirm(id) {
      this.loader = true;
      let data = new FormData();
      data.append("id", id);
      data.append("status", 2);
      this.$store
        .dispatch("admin/confirmRegistran", data)
        .then(data => {
          console.log(data);
          this.loader = false;
          this.fetchRegistran();
          this.alert("success", data.message);
        })
        .catch(err => {
          console.log(err);
          this.alert("error", err.message);
          this.loadConfirm = false;
        });
    },
    rejectPsy(id) {
      this.loader = true;

      this.$store
        .dispatch("admin/rejectRegistran", { id: id })
        .then(data => {
          console.log(data);
          this.loader = false;
          this.fetchRegistran();
          this.alert("success", data.message);
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
          this.alert("error", err.message);
          this.loadConfirm = false;
        });
    },

    importList(e) {
      const file = e.target.files[0];
      const data = new FormData();
      data.append("file", file);
      this.$store.dispatch("admin/importAlumni", data).then(data => {
        console.log(data);
        this.alert("success", data.message);
      });
    },

    alert(type, title) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title
      });
    },

    closeDialog() {
      this.dialogList = false;
    }
  }
};
</script>

<style></style>
