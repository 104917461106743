<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card pa-3">
        <v-tabs v-model="tab" class="mb-5">
          <v-tab href="#tab-1">List Registrant</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <Registered />
          </v-tab-item>
          <!-- <v-tab-item value="tab-2">
            <Unregistered />
          </v-tab-item> -->
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import Registered from "../../components/Admin/Registered.vue";
// import Unregistered from "../../components/Admin/Unregistered.vue";
export default {
  components: { Registered },
  name: "request",
  data() {
    return {
      tab: "tab-1"
    };
  }
};
</script>

<style></style>
