<template>
  <v-row justify="start">
    <v-dialog v-model="dialogList" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Import List</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <div class="d-flex flex-column justify-center align-center">
              <img src="../../../assets/img/404.svg" alt="no data" />
              <h3>No Data Found!</h3>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "importlist",

  props: ["dialogList"],

  data() {
    return {};
  },
  methods: {}
};
</script>

<style></style>
